body {
  margin: 0;
  padding: 0;
}

.bg-rose {
  background-color: #F9F1ED;
}

.bg-gris {
  background-color: #E5E5E5;
}

.text-rose {
  color: #CE5D1D;
  ;
}

.btn-rose, #v-pills-tab .active {
  background-color: #CE5D1D !important;
  ;
}

#v-pills-tab .active, #v-pills-tab {
  color: white !important;
}

#v-pills-tabContent .active, #v-pills-tabContent .active:hover{
  border-right: none;
  color : black !important
}



/*Page active*/

.active .nav-item,
.active {
  background-color: transparent;
  color: #CE5D1D !important;
  border-right: 4px solid #CE5D1D;
}

/* Element non actif */
.nav-item {
  color: #767676;
}

/* Mot de passe oublié */
.linktext :hover,
.linktext:visited {
  color: #CE5D1D;
  text-decoration: none;
}

.linktext {
  text-decoration: none;
}

/* formulaire login*/
.containerInfoLogin {
  box-shadow: 2px 1px 3px solid gray;
}

.input-group-text {
  background-color: transparent;
}

.loginborderleft {
  border-left: none;
}

.loginborderright {
  border-right: none;
}

.cursor {
  cursor: pointer;
}

.delete {
  color: rgb(202, 8, 8) !important;
  background-color: rgba(255, 0, 0, 0.2);
}

.edit {
  color: #D4B927 !important;
  background-color: #D4B92766;
}

.bottomgradient {
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 35%, rgba(228, 229, 229, 1) 100%);
  border-bottom: none;
}

.statsFiltre {
  border-radius: 20px !important;
}

.gray-table {
  background-color: #DDDDDD !important;
}

.gray-table-bottom {
  color: #838383;
}

/*Customise fullcalendar*/
.fc-col-header a,
.fc-daygrid-day a {
  color: black !important;
  text-decoration: none;
  font-weight: bold;
}

.fc-col-header a {
  text-transform: uppercase;
  display: inline-block !important;
}

.fc-daygrid-day-top {
  justify-content: start;
}

.fc-button {
  background-color: white !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
  font-size: small !important;
}

.fc-today-button {
  color: black !important;
  font-weight: bold !important;
}

.fc-icon {
  color: black !important;
}


.fc-toolbar-chunk {
  display: inline-block !important;
  flex: auto !important;
  flex-direction: row !important;
}

.fc-toolbar-title {
  text-transform: uppercase !important;
  font-size: medium !important;
  display: inline-block !important;
}

.eventimage {
  width: 40px;
}


.fc-daygrid-event-harness {
  overflow-x: hidden !important;
  overflow: auto !important;
}

/* Customise scrollbar .fc-daygrid-event-harness */

/* Customise scrollbar
/* width */
::-webkit-scrollbar {
  width: 2px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #F9F1ED;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #F9F1ED;
} 


.page-item.active .page-link{
  background-color: #CE5D1D !important;
  color: white !important;
  border-color: transparent !important;
}

.table>:not(caption)>*>*{
  border-bottom-width : 0px !important
}

.bg-filtre{
  background-color: #EFEFEF;
  color: #767676;
}
.entreprise li a{
  padding: 15px;

}
.p-1{
  font-size: 13px;
}
.select{
  width: 205px;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: #f0ecec;
  text-align: center;
}
.search{
  width: 200px;
}

.nav-link .active:hover{
  color: none;
}

.dupliquer-modal{
  position: absolute;
  right: 20px;
  top: 110px;
  /* z-index: 9; */
}
.alert{
  display: none;
}
.voir{
  display: inline-block;
}
.img{
  width: 80px;
  height: 60px;
  object-fit: cover;
}
.hidden{
  display: none;
}
.color{
  background-color: #767676;
  color: #E5E5E5;
}
.legende{
  position: relative;
  left: 100px;
  top: 30px;
}

.sa-button-container .cancel {
  background-color: #929394 !important;
}

.sa-button-container .sa-confirm-button-container .confirm {
  background-color: #ef2c2c !important;
}

.react-datepicker-wrapper, .react-datepicker__input-container, input {
  width: 100% !important;
}

#flexCheckDefault {
  width: 15px !important;
}

input#openToPublic {
  width: 15px !important;
  height: 15px !important;
  margin-right: 5px;
}

input#processCommande {
  width: 15px !important;
  height: 15px !important;
  margin-right: 5px;
}

.input-group {
  flex-wrap: nowrap !important;
}

.form-switch .form-check-input {
  width: 2rem !important;
  margin-left: -2.5em !important;
}